import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Content from "../../components/content"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

//const ContactPage = ({data, pageContext}) => {
import axios from 'axios'
// import Recaptcha from 'react-recaptcha'

class ContactPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            message: '',
            agreement: false,
            captcha: undefined,
            recaptchaInstance: undefined,
            formError: [],
            formSuccess: '',
        }
        this.meta = this.props.data.graphAPI.contentContactUs.meta
        this.content = this.props.data.graphAPI.contentContactUs.content
    }

    componentDidMount() {
        if(this.state.recaptchaInstance) {
            this.state.recaptchaInstance.reset();
        }
    }
    submit = (event) => {
        event.preventDefault()
        this.setState( { formSuccess: '', formError: [] }, this.handleFormEntry )


    }

    handleFormEntry = () => {
        // console.log('SUBMIT')
        // console.log('name: '+this.state.name)
        // console.log('email: '+this.state.email)
        // console.log('message: '+this.state.message)
        // console.log('agreement: '+this.state.agreement)
        // console.log('response: '+this.state.captcha)

        if( this.state.name     === '' ||
            this.state.email    === '' ||
            this.state.address  === '' ||
            this.state.message  === ''
          ) {
            this.setState({ formError: this.state.formError.concat(["Form not filled out completely."]) })
        } else {
            let formData = new FormData() 
            formData.set('name', this.state.name)
            formData.set('email', this.state.email)
            formData.set('message', this.state.message)
            formData.set('address', this.state.address)
            formData.set('website', 'FBMUD 23')
            formData.set('response', this.state.captcha)

            axios.post('https://backend.touchstonedistrictservices.com/mailer', formData, 
            //axios.post('http://touchstone-server.test/mailer', formData,
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    }
                }).then( function(response) {
                    console.log('Response');
                    console.log(response.data);
                    if(response.data.status === "success") {
                        this.clearForm()
                        this.setState({ formSuccess: "Your message has been sent." })
                    } else {
                        this.setState({ formError: this.state.formError.concat(["Something went wrong, check your entry and try again."]) })
                    }

            }.bind(this) ).catch( function(error) {
                console.log(error.response)
            })

            if(this.state.recaptchaInstance) {
                this.state.recaptchaInstance.reset();
            }

        }

    }

    clearForm = () => {
        this.setState({ name: '', email: '', message: ''})
    }

    changeName = (event) => {
        this.setState({ name: event.target.value })
    }
    changeAddress = (event) => {
        this.setState({ address: event.target.value })
    }
    changeAgreement = (event) => {
        this.setState({ agreement: event.target.checked })
    }
    changeEmail = (event) => {
        this.setState({ email: event.target.value })
    }
    changeMessage = (event) => {
        this.setState({ message: event.target.value })
    }
    captchaVerify = (response) => {
        this.setState({ captcha: response })
    }
    onLoadRecaptcha = () => {
        if(this.state.recaptchaInstance) {
            this.state.recaptchaInstance.reset();
        }
    }

    render() {
        return (
            <Layout>
            <SEO title={this.meta.title} />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    {this.content[0].__typename !== 'GRAPHAPI_ComponentPageSplitContentImage' ? (
                        <div className="title-section">
                            <h1 className="title is-size-1">{this.meta.title}</h1>
                        </div>
                    ) : (<></>)}
                    <div className="collapse-content">
                    <Content content={this.content} />
                    </div>
                    <div className="title-section">

                    <div dangerouslySetInnerHTML={{
                        __html: `
                            <noscript class="notification is-danger" style="display: block;">This form requires a javascript enabled browser to function. Please enable javascript or switch to a compatible browser in order to use this form.</noscript>
                        `,
                    }}
                    ></div>
                    <h2 className="title is-size-2">Leave us your feedback.</h2>
                    <div style={{maxWidth: '800px'}}>

                    <form name="contact" onSubmit={this.submit}>
                        { this.state.formError.length !== 0 ? (<ul className="notification is-danger">{this.state.formError.map((error) => (
                            <li key={error} >{error}</li>
                        )) }</ul>): < ></ > }
                        { this.state.formSuccess === '' ? ( < ></ > ) : (<div className="notification is-success">{this.state.formSuccess}</div>)}

                        <div className="field">
                            <label htmlFor="name" className="label">Name</label>
                            <div className="control">
                                <input className="input" type="text" name="name" placeholder="Enter your name" onChange={this.changeName} value={this.state.name} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="email" className="label">Email</label>
                            <div className="control">
                                <input className="input" type="email" name="email" placeholder="Enter your email" onChange={this.changeEmail} value={this.state.email} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="address" className="label">Address</label>
                            <div className="control">
                                <input className="input" type="text" name="address" placeholder="Enter your street address" onChange={this.changeAddress} value={this.state.address} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="message" className="label">Message</label>
                            <div className="control">
                                <textarea className="textarea" name="message" placeholder="Enter your message" onChange={this.changeMessage} value={this.state.message}></textarea>
                            </div>
                        </div>
                        {/* <Recaptcha render="explicit" ref={e => this.state.recaptchaInstance = e} onloadCallback={this.onLoadRecaptcha} verifyCallback={this.captchaVerify} sitekey="6LdHrGYUAAAAAINbB7Ky8vHL79yhss18gbp_2b-v"/> */}
                        <div className="field">
                            <div className="control">
                                <input className="button is-primary" type="submit" name="submit" value="Submit" />
                            </div>
                        </div>
                    </form>
                    </div>
                    </div>

                </div>
                <Footer />
                    </div>
            </div>
            </Layout>
        )
    }
}

export default ContactPage

export const pageQuery = graphql`
    query contactPage {
        graphAPI {
           contentContactUs {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }

                }
            }
        } 
    }
`